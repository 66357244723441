import { defineNuxtPlugin } from '#app'
import axios from 'axios'
import { MatchMarketingLinkLogDocument } from '~/types/graphql'

export default defineNuxtPlugin((nuxtApp) => {
  const { $apolloProvider, $amplitude } = useNuxtApp()
  return {
    provide: {
      trackMarketingLog: async (userId?: number) => {
        const width = window.screen.width
        const height = window.screen.height
        let ip = ''

        try {
          const response = await axios.get('https://api.ipify.org?format=json')
          ip = response.data.ip
        } catch (e) {
          console.error('IP 조회 실패:', e)
        }

        try {
          await $apolloProvider
            .mutate({
              mutation: MatchMarketingLinkLogDocument,
              variables: {
                ipAddress: ip,
                width,
                height,
                userId: userId || null,
              },
            })
            .then((res) => {
              if (res && userId) {
                $amplitude.track('connect-tracking-match-user')
              } else {
                $amplitude.track('connect-tracking-match')
              }
            })
        } catch (e) {
          console.error('마케팅 로그 기록 실패:', e)
        }
      },
    },
  }
})
