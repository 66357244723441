declare global {
  interface Window {
    kakaoPixel: (key: string) => {
      pageView: () => void
      completeRegistration: () => void
      completeOrder: () => void
      // 필요한 다른 이벤트 메서드들 추가 가능
    }
  }
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  // Kakao Pixel 스크립트 동적 로드
  const loadKakaoPixel = () => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.charset = 'UTF-8'
    script.src = '//t1.daumcdn.net/kas/static/kp.js'
    document.head.appendChild(script)

    // 스크립트 로드 완료 후 초기화
    script.onload = () => {
      if (window.kakaoPixel) {
        window.kakaoPixel('4870771893363716892').pageView()
      }
    }
  }

  // 클라이언트 사이드에서만 실행
  if (process.client) {
    loadKakaoPixel()
  }

  return {
    provide: {
      kakaoPixel: {
        pageView: () => {
          if (window.kakaoPixel) {
            window.kakaoPixel('4870771893363716892').pageView()
          }
        },
        completeRegistration: () => {
          if (window.kakaoPixel) {
            window.kakaoPixel('4870771893363716892').completeRegistration()
          }
        },
        completeOrder: () => {
          if (window.kakaoPixel) {
            window.kakaoPixel('4870771893363716892').completeOrder()
          }
        },
      },
    },
  }
})
